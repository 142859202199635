<template>
  <div class="business-box">
    <bannerStatic :num="2" :title="'打造智能制造核心工厂，助力企业实现工业4.0'"/>
    <a-anchor class="tab-title" :wrapperClass="'tab'"  :showInkInFixed="false" :bounds="200" @click="handleClick" >
      <!-- <a-anchor-link  :key="1" :href="'#business1'" :class="{'ant-anchor-link-active1':isFrist}"  title="业务优势" >
         <IconFont class="iconstyle" :type="`icon-hezuomubiao`" />
      </a-anchor-link>
      <a-anchor-link  :key="2" :href="'#business2'"  title="业务全景" >
         <IconFont class="iconstyle" :type="`icon-hezuomubiao`" />
      </a-anchor-link> -->
      <a-anchor-link  :key="3" :href="'#business3'"  title="产品介绍" :class="{'ant-anchor-link-active1':isFrist}" >
         <IconFont class="iconstyle" :type="`icon-hezuomubiao`" />
      </a-anchor-link>
      <a-anchor-link  :key="4" :href="'#business4'"   title="案例展示" >
         <IconFont class="iconstyle" :type="`icon-hezuomubiao`" />
      </a-anchor-link>
    </a-anchor>
    <div class="content-box">
       <!-- <div class="box1"  :id="'business1'" >
         <h3 class="content-title">业务优势</h3>
         <p class="content-txt">
           <img src="../../assets/img/yw1.jpg" alt="" srcset="">
         </p>
       </div>
       <div class="box2"  :id="'business2'" >
         <h3 class="content-title">业务全景</h3>
         <p class="content-txt">
           <img src="../../assets/img/yw2.jpg" alt="" srcset="">
         </p>
       </div> -->
        <div class="box3"  :id="'business3'" >
         <h3 class="content-title">产品介绍</h3>
         <div class="content-txt">
           <div class="box">
            <a-tabs defaultActiveKey="1" :animated="false" @change="typechangeFunc">
              <a-tab-pane v-for="items in typeList" :tab="items.moduleName" :key="items.moduleId">
                <ul>
                  <li class="tab-item" v-for="item in typechange" :key="item.id">
                     <router-link class="link-hover"  :to="`/BusinessPage/${item.id}`">
                        <a-row type="flex" justify="space-around" align="middle">
                          <a-col :span="12">  <p class="name">{{item.name}}</p></a-col>
                          <a-col :span="12" class="right">
                            <img class="normal" :src="item.img" alt="" srcset="">
                            <img class="hover" :src="item.img2" alt="" srcset=""></a-col>
                        </a-row>
                       </router-link>
                  </li>
                </ul>
                 <div v-show="isNull">
                    <p style="margin-top:50px;">暂无数据</p>
                 </div>
              </a-tab-pane>
            </a-tabs>
          </div>
         </div>
       </div>
        <div class="box4"  :id="'business4'" >
         <h3 class="content-title">案例展示</h3>
         <div class="content-txt">
           <ul>
             <li v-for="item in solutionList" :key="item.id">
              <router-link class="link-hover"  :to="`/BusinessPage/${item.id}`">
                <div class="banner" >
                  <img :src="item.logoUrl" alt="" >
                </div> 
                <div class="descbox" data-v-5f821540="">
                 <p class="name">{{item.name}}</p>
                  <div class="content">
                   {{item.txt}}
                  </div>
                  <!-- <p class="label"><b>关键词</b>：智能制造  工业4.0  生产线</p> -->
                  <span>了解更多</span>
                </div>
             </router-link>
             </li>
           </ul>
         </div>
       </div>
    </div>
  </div>
</template>
<script>
import bannerStatic from'@/components/BannerStatic/BannerStatic'
import IconFont from'@/components/Icon'
import { getProductCase,getProductType } from '@/api/data'
import  { newsImg } from '@/config/appSetting'
export default {
  name: 'Cooperation',
  components:{
    IconFont,
    bannerStatic
  },
  data(){
    return {
      isFrist:true,
      newsImg,
      solutionList:[],
      productList:[],
      typeList:[
        {
          moduleName:"全部",
          moduleId:0
        }
      ],
      nowType:0,
      isNull:false
    }
  },
  computed: {
    typechange(){
      let _this=this
      let arr=[]
      if(this.nowType==0){
        arr=this.productList
      }else{
        arr=this.productList.filter(function (number) {
          return number.moduleId ==_this.nowType
        })
      }
      this.isNull=arr.length>0?false:true
      return arr
    }
  },
  mounted() {
      window.addEventListener("scroll", this.handleScroll);
      this.getType().then(
       this.getData()
      )
  },
  methods:{
    typechangeFunc(key){
      this.nowType=key
    },
    handleClick(e, link) {
      e.preventDefault();
      if(link.title!=="产品介绍"){
       this.isFrist=false
      }else{
       this.isFrist=true
      }
    },
    handleScroll() {
      let scrollTop =window.pageYOffset ||document.documentElement.scrollTop ||document.body.scrollTop;
      this.scrollTop=scrollTop
      if(scrollTop<600){
          this.isFrist=true
      }else{
        this.isFrist=false
      }
    },
    getType(){
      let _this=this
      return getProductType({type:1}).then(res=>{
         if (res.state) {
          if(res.data.length>0){
            res.data.map((item)=>{
               _this.typeList.push(item)
            })
          }
         }
      })
    },
     getData(){
        let _this=this
        _this.productList=[]
        _this.solutionList=[]
        _this.isNull=false
        return getProductCase().then(res => {
            if (res.state) {
                if(res.data.length>0){
                    res.data.map((item)=>{
                        if(item.productType==1&&item.isEnable){
                            let imgUrl=item.productCaseImages&&item.productCaseImages[0]?_this.newsImg+item.productCaseImages[0].imageUrl:''
                            let hoverImgUrl=item.productCaseImages&&item.productCaseImages[1]?_this.newsImg+item.productCaseImages[1].imageUrl:''
                             _this.productList.push({
                                 id:item.productID,
                                 name:item.productName,
                                 txt:item.productContent,
                                 img:imgUrl,
                                 img2:hoverImgUrl,
                                 moduleId:item.moduleId
                             })
                        }
                        if(item.productType==2&&item.isEnable){
                              let imgurl=item.productImageUrl?_this.newsImg+item.productImageUrl:''
                              let logoUrl=item.productCaseImages[0]?_this.newsImg+item.productCaseImages[0].imageUrl:''
                             _this.solutionList.push({
                                 id:item.productID,
                                 name:item.productName,
                                 txt:item.productContent,
                                 imgurl:imgurl,
                                 logoUrl:logoUrl
                             })
                        }
                    })
                    _this.isNull= _this.productList.length>0?false:true
                }else{
                    _this.productList=[]
                    _this.solutionList=[]
                    _this.isNull=false
                }
            }
        })
      }
  },
   beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll);
  },
}
</script>
<style lang="less" >
.business-box{
  .tab-title{
    .tab{
      height: 60px;
      overflow: hidden;
      box-shadow: 0px 0px 10px 0px 
    rgba(37, 51, 74, 0.15);
      color: #333333;
      .ant-anchor-link {
          display: inline-block;
          height: 60px;
          line-height: 58px;
          margin:0 50px;
          padding: 0 0px 0 25px;
          position: relative;
          font-size:16px;
          .iconstyle {
              position: absolute;
              top: 20px;
              left: 0;
              font-size: 20px;
          }
      }
    }
    .ant-affix{
      z-index: 999999;
      background: transparent;
      padding-bottom:30px;
    }
  }
.content-box{
  .content-title{
    margin:70px 0 35px;
    display: inline-block;
    font-weight: 500;
    font-size: 24px;
    &::after {
      content: '';
      display: block;
      width: 42px;
      height: 4px;
      background-color: #f9a825;
      border-radius: 2px;
      margin: 10px auto 0; 
    }
  }
  .box1{
    min-height:646px;
    background: #f9fafd;
    overflow: hidden;
  }
  .box2{
    min-height:722px;
    background: #121f3a;
    overflow: hidden;
    .content-title{
      color:#fff;
    }
  }
  .box3{
    .ant-tabs-bar {
        width: 1200px;
        margin: 0 auto;
      .ant-tabs-tab{
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        color: #333333;
        padding:12px 30px;
      }

    }
    .ant-tabs-content{
      background: #f9fafd;
      min-height: 360px;
      text-align: center;
      .hover{
        display: none
      }
      ul{
        overflow: hidden;
        width: 1200px;
        margin:0 auto;
      }
      .tab-item{
        position: relative;
        width: 344px;
        height: 222px;
        float: left;
        background-color: #ffffff;
        box-shadow: 0px 0px 15px 0px 
          rgba(27, 81, 241, 0.15);
        border-radius: 4px;
        margin:50px 25px;
        -webkit-transition-property: color;
        transition-property: color;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        overflow: hidden;
        .ant-row-flex {
          height: 100%;
          padding:38px;
          position: relative;
          z-index: 2;
          .name{
            	width: 116px;
              font-size: 19px;
              font-weight: normal;
              font-stretch: normal;
              color: #333333;
              text-align: left;
              padding: 0 0 0 15px;
              margin:0;
              line-height: 25px;
              &::before{
                content: '';
                position: absolute;
                top:3px;
                left: 0;
                display:block;
                width: 4px;
                height: 20px;
                background-color: #2690f3;
              }
          }
          .right{
            text-align: right;
          }
        }
        &::before{
          content: "";
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #ffffff;
          -webkit-transition-property: background;
          transition-property: background;
          -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
          -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
        }
        &:hover{
          .name{
            color: #fff;
            &::before{
              background: #fff;
            }
          }
          .right{
            .normal{
              display: none;
            }
            .hover{
              display: inline-block;
            }
          }
        }
        &:hover::before{
          background: #2690f3;
        }
      }
    }
  }
  .box4{
    min-height:896px;
    width: 1200px;
    margin: 0 auto;
    .content-txt{
      ul{
        li{
          float: left;
          background: #fff;
          border: solid 1px #e7e7e7;
          transition: all .3s ease-out;
          a{
            display: inline-block;
            height: 550px;
            width: 345px;
            overflow: hidden;
            text-align: left;
            .banner {
                height: 203px;
                overflow: hidden;
                img{
                  object-fit: cover;
                  width: 100%;
                  height: 100%;
                  transition: all .3s;
                }
            }
            .descbox {
                font-family: "微软雅黑";
                padding: 30px 19px 0 23px;
                .name{
                  font-size: 18px;
                  letter-spacing: 0px;
                  color: #333333;
                  line-height: 20px;
                  margin:0 0 16px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  font-weight: 600;
                }
                .content{
                  height: 120px;
                  font-size: 16px;
                  font-weight: 500;
                  font-stretch: normal;
                  letter-spacing: 0px;
                  color: #333333;
                  line-height: 1.5;
                   display: -webkit-box;
                  -webkit-line-clamp: 5;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                }
                .label{
                  font-size: 16px;
                  line-height: 16px;
                  color:#333;
                  margin-top:20px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  font-weight: 500;
                }
                span{
                  color: #008aff;
                  font-size: 15px;
                  margin-top: 60px;
                  display: block;
                  background: url(../../assets/img/yw-arrow.jpg) no-repeat 0 center;
                  background-position:0 center;
                  padding-left: 50px;
                  transition: all .3s ease-out;
                }
            }
          }
          &:hover{
            box-shadow: 0 6px 20px 0 rgba(115,124,139,.3);
            .banner{
              img{
                transform: scale(1.13);
              }
            }
          }
          &:nth-child(3n-1){
            margin:0 79px;
          }
        }
      }
    }
  }
}
.ant-anchor-link-active > .ant-anchor-link-title {
    color: #333333;
}
.ant-anchor-link-active{
    border-bottom: 2px solid #1890ff;
}
.ant-anchor-link-active1{
    border-bottom: 2px solid #1890ff;
}
.ant-anchor-ink-ball.visible{
  display: none !important;
}
a,a:visited,a:link{
  text-decoration: none;
  color: #333333;
}
}
</style>